/**
 * Konstanten für das UI
 */
export class UiConstants {
    /**
     * Localization string
     */
    static readonly LOCALE_AT = 'de-AT';

    /**
     * UI Initialisierung
     */
    static readonly UIINFO = 'UI-Info';
    static readonly UIRECHTE = 'UI-Rechte';
    static readonly WAPPEN = 'wappen';
    static readonly WAPPEN_IMAGE = 'wappen-image';
    static readonly START_MANDANT = 'start-mandant';
    static readonly THEME = 'farb-schema';
    static readonly UNTERSCHRIFT_VORANGESTELLT = 'unterschrift-vorangestellt';
    static readonly UNTERSCHRIFT = 'unterschrift';
    static readonly INFORMATION_LOADED = 'Informationen wurden geladen.';
    static readonly INFORMATION_ERROR = 'Fehler beim Laden der Informationen.';
    static readonly HOME_DASHBOARD = 'home-dashboard-administration';
    static readonly HOME_DASHBOARD_ACTIVE_FILTERS = 'home-dashboard-administration-active-filters';

    static readonly WAPPEN_AND_LOGO_FALLBACK_URL = 'assets/img/wappen-logo-placeholder.png';
    static readonly WAPPEN_AND_LOGO_FOR_ZENTRALEN_DRUCK_FALLBACK_URL = 'assets/img/vorschau-nicht-verfuegbar.jpg';

    /**
     * Header Konstanten
     */
    static readonly MANDANT_ID_HEADER = 'k5-MandantID';

    /**
     * Konstanten für die Konfiguration
     */
    static readonly SAVE_COLOR_SUCCESS = 'Ausgewähltes Farbschema erfolgreich gesichert.';
    static readonly SAVE_COLOR_ERROR = 'Sicherung des ausgewählten Farbschemas fehlgeschlagen!';

    /**
     * Rechte
     */
    static readonly PROTOKOLL_READ = 'protokoll-read';
    static readonly ADMINISTRATOR_MODIFY = 'administrator-modify';
    static readonly BENUTZER_MODIFY = 'benutzer-modify';
    static readonly BENUTZER_DELETE = 'benutzer-delete';
    static readonly ROLLE_MODIFY = 'rolle-modify';
    static readonly ROLLE_DELETE = 'rolle-delete';
    static readonly MANDANT_CREATE = 'mandant-create';
    static readonly MANDANT_MODIFY = 'mandant-modify';
    static readonly MANDANT_DELETE = 'mandant-delete';
    static readonly MODUL_READ = 'modul-read';
    static readonly MODUL_MODIFY = 'modul-modify';
    static readonly PERSON_READ = 'person-read';
    static readonly PERSON_PROCESS = 'person-process';
    static readonly PERSON_DELETE = 'person-delete';
    static readonly PERSON_GENERATE = 'person-testdaten-generate';
    static readonly KONTAKT_READ = 'kontakt-read';
    static readonly KONTAKT_PROCESS = 'kontakt-process';
    static readonly KONTAKT_DELETE = 'kontakt-delete';
    static readonly WAHL_READ = 'wahl-read';
    static readonly WAHL_PROCESS = 'wahl-process';
    static readonly WAHL_ADMINISTRATION = 'wahl-administration';
    static readonly WAHL_MONITORING = 'wahl-monitoring';
    static readonly WAHL_GENERATE = 'wahl-testdaten-generate';
    static readonly STATUS_VIEW = 'status-view';

    /**
     * Labels
     */
    static readonly HOME_LABEL = 'Home';
    static readonly MANDANTEN_LABEL = 'Mandanten';
    static readonly BENUTZERVERWALTUNG_LABEL = 'Benutzer';
    static readonly MODULE_MANAGEMENT_LABEL = 'Module';
    static readonly ROLLEN_LABEL = 'Rollen';
    static readonly DL_ROLLEN_LABEL = 'Bereitgestellte Rollen';
    static readonly EIGENE_ROLLEN_LABEL = 'Systemrollen / eigene Rollen';
    static readonly ADMINISTRATION_LABEL = 'Administration';
    static readonly WAHLEN_LABEL = 'Wahlen';
    static readonly STATUS_LABEL = 'Status';
    static readonly USER_LABEL = 'Benutzerprofil';

    /**
     * Icons
     */
    static readonly HOME_ICON = 'home-alt';
    static readonly MANDANTEN_ICON = 'house-user';
    static readonly BENUTZERVERWALTUNG_ICON = 'user-friends';
    static readonly ROLLEN_ICON = 'key';
    static readonly DL_ROLLEN_ICON = 'chart-network';
    static readonly EIGENE_ROLLEN_ICON = 'house-user';
    static readonly MODULE_MANAGEMENT_ICON = 'wrench';
    static readonly ADMINISTRATION_ICON = 'sliders-h';
    static readonly WAHLEN_ICON = 'k5-wahl-alt';
    static readonly STATUS_ICON = 'k5-server-check-primary';
    static readonly CHECK_ICON = 'fa-check';
    static readonly XMARK_ICON = 'fa-regular fa-xmark';

    /**
     * Titles
     */
    static readonly STANDARD_TITLE = 'Home';
    static readonly BENUTZER_TITLE = 'Benutzer';
    static readonly MANDANTEN_TITLE = 'Mandanten';
    static readonly ROLLEN_TITLE = 'Rollen';
    static readonly DL_ROLLEN_TITLE = 'Bereitgestellte Rollen';
    static readonly EIGENE_ROLLEN_TITLE = 'Systemrollen / eigene Rollen';
    static readonly MODULE_TITLE = 'Module';
    static readonly ADMINISTRATION_TITLE = 'Administration';
    static readonly WAHLEN_TITLE = 'Wahlen';
    static readonly STATUS_TITLE = 'Status';

    /**
     * Farben
     */
    static readonly TEXT_FG_PRIMARY = 'fg-primary';
    static readonly TEXT_RED_500 = 'text-red-500';

    /**
     * Positions
     */
    static readonly POSITION_CENTER = 'center';

    /**
     * Mandant-Einstellungen
     */
    static readonly VORSCHAU_AKTIV = 'vorschau-aktiv';
    static readonly ADRESSIERUNG = 'adressierung';
    static readonly STAMMPORTAL = 'stammportal';
    static readonly WEBSITE = 'website';
    static readonly DATENSCHUTZHINWEIS = 'datenschutzhinweis';
    static readonly ERREICHBARKEIT = 'erreichbarkeit';
    static readonly VKZ = 'vkz';
    static readonly WAHLVORBEREITUNG_LIZENSIERT = 'wahlvorbereitung-lizensiert';
    static readonly KONTAKTMANAGEMENT_LIZENSIERT = 'kontaktmanagement-lizensiert';

    static readonly HOME_ID = 'nav-content-k5-nav-link-home';
    static readonly MANDANTEN_ID = 'nav-content-k5-nav-link-mandanten';
    static readonly BENUTZERVERWALTUNG_ID = 'nav-content-k5-nav-link-benutzerverwaltung';
    static readonly ROLLEN_ID = 'nav-content-k5-nav-link-rollen';
    static readonly DL_ROLLEN_ID = 'nav-content-k5-nav-link-dl-rollen';
    static readonly EIGENE_ROLLEN_ID = 'nav-content-k5-nav-link-eigene-rollen';
    static readonly MANAGEMENT_ID = 'nav-content-k5-nav-link-management';
    static readonly ADMINISTRATION_ID = 'nav-content-k5-nav-link-administration';
    static readonly WAHL_ID = 'nav-content-k5-nav-link-wahl';
    static readonly STATUS_ID = 'nav-content-k5-nav-link-status';

    /**
     * True / False Strings
     */
    static readonly TRUE = 'true';
    static readonly FALSE = 'false';

    /**
     * Wahlkonstanten
     */
    // Hilfswert für den Aufruf einer neue anzulegenden zentralen Wahl
    // Komponente benötigt für den Aufruf eine ID
    static readonly NEUE_WAHL = 'neue-wahl';

    /**
     * Application Constanten
     */
    static readonly WAHLTAG_NAME = 'Wahltag';
    static readonly VERFAHREN_NAME = 'Verfahren';

    /**
     * Konstanten für Widget Keys
     */
    static readonly MONITORING_WIDGET_KEY = 'monitoring-widget';

    /**
     * Konstanten für Feature Flags
     */
    static readonly FEATURE_FLAG_MONITORING_WIDGET = 'showMonitoringWidget';
    static readonly FEATURE_FLAG_DASHBOARD = 'showDashboard';

    /**
     * Konstanten für Datentypen
     */
    static readonly INTEGER = 'integer';
    static readonly SECRET = 'secret';
    static readonly STRING = 'string';
    static readonly BOOLEAN = 'boolean';
}
