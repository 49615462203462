import { Pipe, PipeTransform } from '@angular/core';

/**
 * Konvertiert Werte der Typen 'boolean', 'number'
 * und 'string' in die Textdarstellung 'ja' oder 'nein'.
 *
 * Typ 'boolean':
 *     true -> ja
 *     nein -> nein
 *     null/undefined -> nein
 *
 * Typ 'number':
 *     <0 -> ja
 *     0 -> nein
 *     >0 -> ja
 *     null/undefined -> nein
 *
 * Typ 'string':
 *     'true'/'ja'/ -> ja
 *     jeder andere String -> nein
 *     null/undefined -> nein
 *
 * Verwendung: {{ value | jaNein }}
 */
@Pipe({
  name: 'jaNein'
})
export class JaNeinPipe implements PipeTransform {

    private JA: string = 'ja';
    private NEIN: string = 'nein';

    /**
     * Übersetzt den übergebenen Wert in die Textdarstellung 'ja' oder 'nein'.
     * @param value Hier wird der Wert übergeben, der zu 'ja' oder 'nein' übersetzt wird.
     */
     transform(value?: boolean | number | string): string {

        let result = this.NEIN;
        if (value) {

            /* Gelangt die Ausführung hierher, sind folgende Fälle abgedeckt:
             *
             * Typ 'boolean': true
             * Typ 'number': <>0
             * Typ 'string': enthält einen Text
             */

            if (typeof(value) === 'string') {

                // konkrete Strings prüfen
                value = value.toLowerCase();
                if ((value === 'true') ||
                    (value === 'ja')) {

                    result = this.JA;
                }
            }
            else {

                // für alle anderen Typen gilt an dieser Stelle: ja
                result = this.JA;
            }
        }
        else {

            /* Gelangt die Ausführung hierher, sind folgende Fälle abgedeckt:
             *
             * Typ 'boolean': null/undefined/false
             * Typ 'number': null/undefined/0
             * Typ 'string': null/undefined/''
             */
        }

        return result;
    }
}
