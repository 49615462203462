import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

/**
 * Interface für das Laden der Deployment Konfiguration aus der 'deployment-config.json'
 */
export interface AppConfig {
    env: {
        name: string;
        snackBarDuration: number;
        snackBarDurationError: number;
    };
    appInsights: {
        instrumentationKey: string;
    };
    k5storage: {
        storageUrl: string;
    };
    k5Identity: {
        k5IdentityDiscoveryUrl: string;
    };
    k5Mandanten: {
        mandantenServiceUrl: string;
        mandantenServiceBaseUrl: string;
        mandantenServiceBaseUrlUIApi: string;
        mandantenServiceUiInitializationUrl: string;
    };
    k5civisKm: {
        kontaktmanagementUiApiBaseUrl: string;
    };
    k5civisWv: {
        wahlvorbereitungUiApiUrl: string;
    };
    k5Personen: {
        personenApiUrl: string;
        personenUiApiUrl: string;
    };
    k5SignalR: {
        signalrApiUrl: string;
    };
    k5Status: {
        statusUrl: string;
    };
    k5NextHilfe: {
        baseUrl: string;
    };
}

/**
 * Interface für das Laden der Build Informationen aus der 'buildInfo.json'
 */
export interface BuildInfo {
    buildNumber: string;
}

/**
 * Lädt die Kofiguration für die aktuelle Deployment Stage aus der mitgelieferten Konfigurationsdatei
 * 'deployment-config.json' aus dem Assets Ordner.
 * In der Anwendung werden die Aufrufe der Schnittstellen an die der Konfigurationsdatei angepasst.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigAssetLoaderService {
    private readonly CONFIG_URL = 'assets/config/config.json';
    private readonly BUILD_INFO_URL = 'assets/buildInfo.json';
    private configuration?: AppConfig;
    private buildInfo?: BuildInfo;

    /**
     * Konstruktor
     * @param http HttpClient
     */
    constructor(private http: HttpClient) {}

    /**
     * Lädt die Deployment Konfiguration aus der mitgelieferten Konfigurations-Datei.
     */
    public async loadConfig(): Promise<void> {
        // Observable für das Laden der Konfiguration
        const headers = new HttpHeaders({ 'Cache-Control': 'no-cache' });
        const configuration$ = this.http.get<AppConfig>(this.CONFIG_URL, { headers });
        // Zuweisung des letzten Wertes des Observables als Promise
        this.configuration = await lastValueFrom(configuration$);
    }

    /**
     * Lädt die Build Info aus der Konfigurationsdatei
     */
    public async loadBuildInfo(): Promise<void> {
        // Observable für das Laden der Konfiguration
        const headers = new HttpHeaders({ 'Cache-Control': 'no-cache' });
        const buildInfo$ = this.http.get<BuildInfo>(this.BUILD_INFO_URL, { headers });
        // Zuweisung des letzten Wertes des Observables als Promise
        this.buildInfo = await lastValueFrom(buildInfo$);
    }

    /**
     * Liefert die Konfiguration für das aktuelle Deployment oder Defaultvalues zurück,
     * falls die Konfigurationsdatei nicht gelesen werden kann.
     */
    public getConfig(): AppConfig {
        if (!this.configuration) {
            return this.defaultConfiguration();
        }
        return this.configuration;
    }

    /**
     * Liefert die Buildinformationen der Applikation oder einen Defaultwert zurück
     */
    public getBuildInfo(): BuildInfo {
        if (!this.buildInfo) {
            return this.defaultBuildInfo();
        }
        return this.buildInfo;
    }

    /**
     * Liefert den Namen der Stage aus der geladenen Konfiguration oder der Default-Konfiguration zurück.
     * @returns Stage-Name string
     */
    public getEnvironment(): string {
        return this.configuration ? this.configuration.env.name : this.defaultConfiguration().env.name;
    }

    /**
     * Liefert eine Konfiguration mit Default-Werten zurück, welche für die Unit-Tests oder als Hilfe beim Auffinden
     * von Fehlern in der Anwendunge verwendet wird.
     */
    private defaultConfiguration(): AppConfig {
        return {
            env: {
                name: 'default',
                snackBarDuration: 2000,
                snackBarDurationError: 8000
            },
            appInsights: {
                instrumentationKey: ''
            },
            k5storage: {
                storageUrl: 'https://default-civis-storage.blob.core.windows.net/ui-assets'
            },
            k5Identity: {
                k5IdentityDiscoveryUrl:
                    'https://default-k5identity-app.azurewebsites.net/.well-known/openid-configuration'
            },
            k5Mandanten: {
                mandantenServiceUrl: 'https://default-k5civis-mand-app.azurewebsites.net',
                mandantenServiceBaseUrl: 'https://default-k5civis-mand-app.azurewebsites.net/api/mandanten',
                mandantenServiceBaseUrlUIApi: 'https://default-k5civis-mand-app.azurewebsites.net/ui-api/mandanten',
                mandantenServiceUiInitializationUrl:
                    'https://default-k5civis-mand-app.azurewebsites.net/ui-api/mandanten/benutzer/initialisierung'
            },
            k5civisKm: {
                kontaktmanagementUiApiBaseUrl:
                    'https://default-k5civis-km-app.azurewebsites.net/ui-api/kontaktmanagement'
            },
            k5civisWv: {
                wahlvorbereitungUiApiUrl: 'https://default-k5civis-wv-app.azurewebsites.net/ui-api/Wahlvorbereitung'
            },
            k5Personen: {
                personenApiUrl: 'https://default-pers-app.azurewebsites.net/api',
                personenUiApiUrl: 'https://default-pers-app.azurewebsites.net/ui-api'
            },
            k5SignalR: {
                signalrApiUrl: 'https://default-signalr-app.azurewebsites.net/api/SignalR'
            },
            k5Status: {
                statusUrl: 'https://default-status-app.azurewebsites.net'
            },
            k5NextHilfe: {
                baseUrl: 'https://hilfe.k5next.at'
            }
        };
    }

    /**
     * Liefert Build Informationen mit Default-Werten zurück
     */
    private defaultBuildInfo(): BuildInfo {
        return {
            buildNumber: '<-- no build number found -->'
        };
    }
}
