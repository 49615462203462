// @ts-strict-ignore
import { UiConstants } from '@core/constants/ui-constants';
import { RouterLinkConstants } from '@core/constants/url-constants';

/**
 * Model class for navigation links
 */
export class NavItem {
    label: string;
    icon: string;
    routerLink: string;
    url: string;
    navLevel: number;
    position: 'start' | 'center' | 'end';
    permissions?: string[];
    exactUrlActiveMatching: boolean;
    childrenVisible: boolean = false;
    cypressId: string;

    children?: NavItem[];

    get hasChildren(): boolean {
        return this.children?.length > 0;
    }

    constructor(
        label: string,
        icon: string,
        permissions: string[],
        routerLink: string,
        url: string | null,
        children: NavItem[],
        navLevel: number,
        position: 'start' | 'center' | 'end',
        exactUrlActiveMatching: boolean,
        cypressId: string
    ) {
        this.label = label;
        this.icon = icon;
        this.routerLink = routerLink;
        this.url = url;
        this.navLevel = navLevel;
        this.position = position;
        this.permissions = permissions;
        this.children = children;
        this.exactUrlActiveMatching = exactUrlActiveMatching;
        this.cypressId = cypressId;
    }
}

/**
 * Navigationslinks für Bürgerservice
 */
export const NAV_ITEMS: NavItem[] = [
    /**
     * Navigationslinks für die Verwaltungsoberfläche
     *
     * Permissions sind mit 'oder' verknüpft
     */

    new NavItem(
        UiConstants.HOME_LABEL,
        UiConstants.HOME_ICON,
        [],
        RouterLinkConstants.HOME,
        null,
        [],
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.HOME_ID
    ),
    new NavItem(
        UiConstants.MANDANTEN_LABEL,
        UiConstants.MANDANTEN_ICON,
        [UiConstants.MANDANT_MODIFY, UiConstants.MANDANT_DELETE, UiConstants.MANDANT_CREATE],
        RouterLinkConstants.MANDANTEN,
        null,
        [],
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.MANDANTEN_ID
    ),
    new NavItem(
        UiConstants.BENUTZERVERWALTUNG_LABEL,
        UiConstants.BENUTZERVERWALTUNG_ICON,
        [UiConstants.BENUTZER_MODIFY, UiConstants.ADMINISTRATOR_MODIFY, UiConstants.BENUTZER_DELETE],
        RouterLinkConstants.BENUTZERVERWALTUNG,
        null,
        [],
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.BENUTZERVERWALTUNG_ID
    ),
    new NavItem(
        UiConstants.ROLLEN_LABEL,
        UiConstants.ROLLEN_ICON,
        [UiConstants.ROLLE_MODIFY, UiConstants.ROLLE_DELETE],
        RouterLinkConstants.ROLLEN,
        null,
        [],
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.ROLLEN_ID
    ),
    new NavItem(
        UiConstants.MODULE_MANAGEMENT_LABEL,
        UiConstants.MODULE_MANAGEMENT_ICON,
        [UiConstants.MODUL_READ, UiConstants.MODUL_MODIFY],
        RouterLinkConstants.MODULE_MANAGEMENT,
        null,
        [],
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.MANAGEMENT_ID
    ),
    new NavItem(
        UiConstants.ADMINISTRATION_LABEL,
        UiConstants.ADMINISTRATION_ICON,
        [UiConstants.PERSON_GENERATE, UiConstants.WAHL_GENERATE],
        RouterLinkConstants.ADMINISTRATION,
        null,
        null,
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.ADMINISTRATION_ID
    ),
    new NavItem(
        UiConstants.WAHLEN_LABEL,
        UiConstants.WAHLEN_ICON,
        [UiConstants.WAHL_ADMINISTRATION, UiConstants.WAHL_MONITORING],
        RouterLinkConstants.WAHLEN,
        null,
        null,
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.WAHL_ID
    ),
    new NavItem(
        UiConstants.STATUS_LABEL,
        UiConstants.STATUS_ICON,
        [UiConstants.STATUS_VIEW],
        RouterLinkConstants.STATUS,
        null,
        null,
        1,
        UiConstants.POSITION_CENTER,
        false,
        UiConstants.STATUS_ID
    )
];
