// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterCategory } from '@shared/models/filterCategory';

@Component({
    selector: 'k5-list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit, OnChanges {
    filterClicked = false;

    @Input()
    filterCategories: FilterCategory[] = [];

    anzahlFilterAktiv: number = 0;

    @Output()
    filterChanged: EventEmitter<FilterCategory[]> = new EventEmitter<FilterCategory[]>();

    /**
     * Initialisiert den Filter mit den vorausgewählten Optionen
     */
    ngOnInit(): void {
        this.anzahlFilterAktiv = this.getFilterCount(this.filterCategories);
    }

    /**
     * Aktualisiert den Filter, wenn von außen die Optionen verändert wurden
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        const filterCategoriesChange = changes['filterCategories'];
        if (filterCategoriesChange && !filterCategoriesChange.firstChange) {
            this.anzahlFilterAktiv = this.getFilterCount(this.filterCategories);
        }
    }

    /**
     * Berechnet die Anzahl an aktiven Filtern
     * @param filterCategories Filterkategorien
     * @returns Anzahl der aktiven Filter
     */
    getFilterCount(filterCategories: FilterCategory[]): number {
        let filterActiveCount = 0;
        for (let category of filterCategories) {
            for (let option of category.filterOptions) {
                if (option.selected) {
                    filterActiveCount++;
                }
            }
        }
        return filterActiveCount;
    }

    /**
     * Klappt das Filtermenü auf
     */
    toggleFilter(): void {
        this.filterClicked = !this.filterClicked;
    }

    /**
     * Resettet den Filter
     */
    resetFilter(): void {
        for (let category of this.filterCategories) {
            for (let option of category.filterOptions) {
                option.selected = false;
            }
        }
        this.anzahlFilterAktiv = 0;
        this.filterChanged.emit(this.filterCategories);
    }

    /**
     * Gibt die Werte zurück, welche gefiltert werden sollen
     */
    optionSelected(filterCategoryIndex: number, filterOptionIndex: number): void {
        const filterOption = this.filterCategories[filterCategoryIndex].filterOptions[filterOptionIndex];
        if (!filterOption.selected) {
            this.anzahlFilterAktiv += 1;
        } else {
            this.anzahlFilterAktiv -= 1;
        }
        this.filterCategories[filterCategoryIndex].filterOptions[filterOptionIndex].selected = !filterOption.selected;
        this.filterChanged.emit(this.filterCategories);
    }
}
