import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogActionsComponent, DialogContentComponent, DialogHeadingComponent } from '@k5next/ui-components';
import { MaterialModule } from '../material/material.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { EditSectionComponent } from './components/edit-section/edit-section.component';
import { EditableCardComponent } from './components/editable-card/editable-card.component';
import { EditableListItemComponent } from './components/editable-list-item/editable-list-item.component';
import { FabMenuComponent } from './components/fab-menu-button/fab-menu.component';
import { FabRightBottomComponent } from './components/fab-right-bottom/fab-right-bottom.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MandantDarstellungComponent } from './components/mandant-darstellung/mandant-darstellung.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ResponsiveTitleComponent } from './components/responsive-title/responsive-title.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { ElementTypePipe } from './pipes/element-type.pipe';
import { EnumKeysPipe } from './pipes/enum-keys.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { JaNeinPipe } from './pipes/ja-nein.pipe';
import { SchnellsucheKategoriePipe } from './pipes/schnellsuche-kategorie.pipe';
import { SimpleEnumToArrayPipe } from './pipes/simple-enum-to-array.pipe';
import { SuchArtPipe } from './pipes/such-art.pipe';
import { WahlkarteUnterschriftPipe } from './pipes/wahlkarte-unterschrift.pipe';
import { MandantServiceUrlPipe } from './pipes/mandant-service-url.pipe';

@NgModule({
    declarations: [
        HighlightPipe,
        SchnellsucheKategoriePipe,
        ElementTypePipe,
        SectionHeaderComponent,
        LoadingComponent,
        ResponsiveTitleComponent,
        EnumKeysPipe,
        SuchArtPipe,
        FabMenuComponent,
        FabRightBottomComponent,
        PageHeaderComponent,
        ConfirmationDialogComponent,
        JaNeinPipe,
        EditSectionComponent,
        WahlkarteUnterschriftPipe,
        EditableListItemComponent,
        EditableCardComponent,
        ListFilterComponent,
        EnumToArrayPipe,
        SimpleEnumToArrayPipe,
        MandantDarstellungComponent,
        MandantServiceUrlPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        DialogHeadingComponent,
        DialogContentComponent,
        DialogActionsComponent,
        HeaderSearchComponent
    ],
    exports: [
        SectionHeaderComponent,
        LoadingComponent,
        ResponsiveTitleComponent,
        EnumKeysPipe,
        FabMenuComponent,
        FabRightBottomComponent,
        PageHeaderComponent,
        HeaderSearchComponent,
        JaNeinPipe,
        EditSectionComponent,
        ConfirmationDialogComponent,
        WahlkarteUnterschriftPipe,
        EditableListItemComponent,
        EditableCardComponent,
        ListFilterComponent,
        EnumToArrayPipe,
        SimpleEnumToArrayPipe,
        MandantDarstellungComponent,
        MandantServiceUrlPipe
    ]
})
export class SharedModule {}
