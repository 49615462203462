<div class="edit-section-container">
    <k5-section-header [title]="heading" [icon]="icon">
        <div class="flex">
            <!-- Aktion zum Editieren des Bereichs -->
            <div *ngIf="isEditPencilVisible" class="ml-2">
                <button
                    mat-icon-button
                    type="button"
                    aria-label="Bearbeiten"
                    [matTooltip]="heading + ' bearbeiten'"
                    id="editButton"
                    class="actionIcon"
                    (click)="switchToEditMode()"
                    data-cy="edit-section-edit-button"
                >
                    <mat-icon fontSet="fa-duotone" fontIcon="fa-pencil" class="fg-primary text-base"></mat-icon>
                </button>
            </div>
            <!-- Aktion zum Hinzufügen -->
            <div *ngIf="isAddButtonVisible" class="ml-2">
                <button
                    mat-icon-button
                    type="button"
                    aria-label="Hinzufügen"
                    [matTooltip]="heading + ' hinzufügen'"
                    id="addButton"
                    class="actionIcon"
                    (click)="switchToAddMode()"
                    data-cy="edit-section-add-button"
                >
                    <mat-icon fontSet="fa-duotone" fontIcon="fa-circle-plus" class="fg-primary text-base"></mat-icon>
                </button>
            </div>

            <div *ngIf="isCustomButtonVisible" class="ml-2">
                <button
                    mat-icon-button
                    type="button"
                    aria-label="Aktion"
                    [matTooltip]="customIconTooltip"
                    id="customButton"
                    class="actionIcon"
                    (click)="triggerCustomEvent()"
                    data-cy="edit-section-custom-button"
                >
                    <mat-icon
                        fontSet="fa-duotone"
                        [fontIcon]="customIcon"
                        class="text-base"
                        [ngClass]="customIconColor"
                    ></mat-icon>
                </button>
            </div>

            <span class="flex-grow"></span>
            <ng-content select="[k5-edit-section-header-addon]"></ng-content>
        </div>
    </k5-section-header>

    <div *ngIf="isEditMode; else readContent" class="p-4">
        <ng-content select="[k5-edit-section-write]"></ng-content>
    </div>

    <ng-template #readContent>
        <ng-content select="[k5-edit-section-read]"></ng-content>
    </ng-template>
</div>
