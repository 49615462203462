import { Pipe, PipeTransform } from '@angular/core';

/**
 * Konvertiert eine Bezeichnung mit Highlight-Markierungen
 * zu der dementsprechenden Html-Darstellung.
 * Beispiel:
 *    [innerHtml]="'|Ber|ger' | highlight"
 *    formattiert zu: <strong>Ber</strong>ger
 * Pipes die keinen Html-String zurückliefern können ganz
 * normal verwendet werden: {{ value | highlight }}
 */
@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

    /**
     * Highlighted markierte Sektionen in der Zeichenkette.
     * @param value string
     */
    transform(value: string): string {
        const data = value.split('|');
        let result = '';
        let isMarked = false;

        data.forEach(element => {
            if (isMarked) {
                result += `<strong>${element}</strong>`;
            } else {
                result += element;
            }
            isMarked = !isMarked;
        });

        return result;
    }

}
