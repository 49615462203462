import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumKeys'
})
export class EnumKeysPipe implements PipeTransform {

    transform(value: any): number[] {
        const keys: number[] = [];
        for (const enumMember in value) {
            if (!isNaN(parseInt(enumMember, 10))) {
                keys.push(parseInt(enumMember, 10));
            }
        }
        return keys;
    }

}
