<div [ngClass]="centerSearch ? 'search-header-addon-center' : 'search-header-addon-right'">
    <form>
        <div class="search-input-wrapper">
            <div class="search-icon-wrapper">
                <i aria-hidden="true" class="fa-duotone fa-lg fa-search"></i>
            </div>
            <div class="input-container">
                <input placeholder="Suche ..." [formControl]="searchFormControl" class="search-input" name="search" />
            </div>
        </div>
    </form>
</div>
