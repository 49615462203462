<div class="darstellung-container" [ngSwitch]="forceDarstellung ?? darstellungData?.darstellungUi">
    <!-- Nur Logo -->
    <div *ngSwitchCase="UI_DARSTELLUNG.Logo" class="darstellung-item">
        <div *ngIf="!displaySmall; else logoSmall" class="k5-flex">
            <img alt="Mandant Logo" class="mandant-logo" [src]="imageSrc" />
        </div>

        <ng-template #logoSmall>
            <div class="avatar">
                {{ avatarText }}
            </div>
        </ng-template>
    </div>

    <!-- Text mit Wappen -->
    <div *ngSwitchCase="UI_DARSTELLUNG.TextMitWappen" class="darstellung-item">
        <div *ngIf="!displaySmall; else textMitWappenSmall" class="flex-space-between">
            <div class="flex-text-container">
                <span class="name-zeile1" data-cy="mandant-name-zeile-1">
                    {{ darstellungData?.nameZeile1 }}
                </span>
                <span class="name-zeile2" data-cy="mandant-name-zeile-2">
                    {{ darstellungData?.nameZeile2 }}
                </span>
            </div>

            <img alt="Mandant Wappen" class="mandant-wappen" [src]="imageSrc" />
        </div>

        <ng-template #textMitWappenSmall>
            <img alt="Mandant Wappen" class="mandant-wappen-small" [src]="imageSrc" />
        </ng-template>
    </div>

    <!-- Default - Nur Text -->
    <div *ngSwitchDefault class="darstellung-item">
        <div *ngIf="!displaySmall; else textSmall" class="flex-text-container">
            <span class="name-zeile1" data-cy="mandant-name-zeile-1">
                {{ darstellungData?.nameZeile1 }}
            </span>
            <span class="name-zeile2" data-cy="mandant-name-zeile-2">
                {{ darstellungData?.nameZeile2 }}
            </span>
        </div>

        <ng-template #textSmall>
            <div class="avatar">
                {{ avatarText }}
            </div>
        </ng-template>
    </div>
</div>
