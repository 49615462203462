// @ts-strict-ignore
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    standalone: true,
    selector: 'k5-header-search',
    imports: [CommonModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * @todo lrzwhap: In weiterer Folge durch Search-Component aus der Komponentenbibliothek ersetzen
 */
export class HeaderSearchComponent implements OnDestroy, OnInit, OnChanges {
    @Output()
    inputChanged: EventEmitter<string>;

    @Input()
    searchInput: string;

    @Input()
    centerSearch: boolean = false;

    @Input()
    debounceTime: number = 0;

    subscriptions = new Subscription();

    searchFormControl: FormControl<string> = new FormControl();

    constructor() {
        this.inputChanged = new EventEmitter<string>();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Setzt den Input, welcher von außen kommt
     * @param changes changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchInput']) {
            this.searchFormControl.setValue(changes['searchInput'].currentValue);
        }
    }

    /**
     * Emittet den input Wert
     */
    ngOnInit(): void {
        this.subscriptions.add(
            this.searchFormControl.valueChanges
                .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
                .subscribe((input: string) => {
                    this.inputChanged.emit(input);
                })
        );
    }
}
