import { Pipe, PipeTransform } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';

@Pipe({
    name: 'mandantServiceUrl'
})
export class MandantServiceUrlPipe implements PipeTransform {
    constructor(private configAssetLoaderService: ConfigAssetLoaderService) {}

    /**
     * Hängt die MandanteService BaseUrl vorangestellt an einen Wert
     */
    transform(value: string): string {
        return this.configAssetLoaderService.getConfig().k5Mandanten.mandantenServiceUrl + value;
    }
}
