import { Pipe, PipeTransform } from '@angular/core';
import { WahlkarteUnterschriftEnum } from '@shared/models/enums';

@Pipe({
    name: 'wahlkarteUnterschrift'
})
export class WahlkarteUnterschriftPipe implements PipeTransform {

    /**
      * Übersetzt Enum-Werte des WahlkarteUnterschriftEnum in eine textuelle Form.
      * @param value WahlkarteUnterschriftEnum
      */
    transform(value: WahlkarteUnterschriftEnum): string {
        switch (value) {
            case WahlkarteUnterschriftEnum.Buergermeister:
                return 'Bürgermeister/in';
            case WahlkarteUnterschriftEnum.Wahlleiter:
                return 'Wahlleiter/in';
            case WahlkarteUnterschriftEnum.Sachbearbeiter:
                return 'Sachbearbeiter/in';
            default:
                return 'Eintrag für die Wahlkartenunterschrift nicht gefunden!';
        }
    }
}
