/**
 * Path constants for routing modules
 */
export class PathConstants {
    /**
     * Verwaltungsoberfläche Konstanten
     */

    static readonly HOME = 'home';

    static readonly SETTINGS = 'einstellungen';
    static readonly USERMANAGEMENT = 'benutzer';
    static readonly ROLES_PERMISSIONS = 'rollenRechte';

    static readonly ADMINISTRATION = 'administration';

    static readonly NOTIFICATIONS = 'benachrichtigungen';
    static readonly CHAT = 'chat';
    static readonly USERPROFILE = 'benutzerprofil';

    static readonly INITIALIZATION = 'initialization';
    static readonly ERROR = 'error';

    static readonly MANDANTEN = 'mandanten';
    static readonly MANDANT_CREATE = 'erstellen';

    static readonly BENUTZERVERWALTUNG = 'benutzer';
    static readonly BENUTZER_DETAIL = 'benutzer';
    static readonly BENUTZER_CREATE = 'benutzererstellung';

    static readonly MODULE_MANAGEMENT = 'module';

    static readonly ROLLEN = 'rollen';
    static readonly EIGENE_ROLLEN = 'eigene';
    static readonly DL_ROLLEN = 'dienstleister';
    static readonly ROLLE_EDIT = 'bearbeiten';
    static readonly ROLLE_CREATE = 'erstellen';

    static readonly WAHLEN = 'wahlen';
    static readonly ZENTRALE_WAHLEN = 'zentrale-wahlen';

    static readonly STATUS = 'status';

    /**
     * Namen von Variablen in URL-Pfaden
     */
    static readonly WAHL_ID = 'wahlId';
    static readonly TAB = 'tab';
    static readonly ZWEITER_WAHLGANG = 'zweiterWahlgang';
}

/**
 * Constants for SPA navigation router links
 */
export class RouterLinkConstants {
    /**
     * Verwaltungsoberfläche Links
     */
    static readonly HOME = `/${PathConstants.HOME}`;

    static readonly ADMINISTRATION = `/${PathConstants.ADMINISTRATION}`;

    static readonly NOTIFICATIONS = `/${PathConstants.NOTIFICATIONS}`;
    static readonly CHAT = `/${PathConstants.CHAT}`;
    static readonly USERPROFILE = `/${PathConstants.USERPROFILE}`;

    static readonly INITIALIZATION = `/${PathConstants.INITIALIZATION}`;
    static readonly ERROR = `/${PathConstants.ERROR}`;

    static readonly MANDANTEN = `/${PathConstants.MANDANTEN}`;
    static readonly MANDANT_CREATE = `/${PathConstants.MANDANTEN}/${PathConstants.MANDANT_CREATE}`;

    static readonly BENUTZERVERWALTUNG = `/${PathConstants.BENUTZERVERWALTUNG}`;
    static readonly BENUTZER_DETAIL = `${RouterLinkConstants.BENUTZERVERWALTUNG}/${PathConstants.BENUTZER_DETAIL}`;
    static readonly BENUTZER_CREATE = `${RouterLinkConstants.BENUTZERVERWALTUNG}/${PathConstants.BENUTZER_CREATE}`;

    static readonly MODULE_MANAGEMENT = `/${PathConstants.MODULE_MANAGEMENT}`;

    static readonly ROLLEN = `/${PathConstants.ROLLEN}`;
    static readonly DL_ROLLEN = `${RouterLinkConstants.ROLLEN}/${PathConstants.DL_ROLLEN}`;
    static readonly EIGENE_ROLLEN = `${RouterLinkConstants.ROLLEN}/${PathConstants.EIGENE_ROLLEN}`;
    static readonly DL_ROLLE_CREATE = `${RouterLinkConstants.ROLLEN}/${PathConstants.DL_ROLLEN}/${PathConstants.ROLLE_CREATE}`;
    static readonly EIGENE_ROLLE_CREATE = `${RouterLinkConstants.ROLLEN}/${PathConstants.EIGENE_ROLLEN}/${PathConstants.ROLLE_CREATE}`;
    static readonly ROLLE_CREATE = `${RouterLinkConstants.ROLLEN}/${PathConstants.ROLLE_CREATE}`;

    static readonly DL_ROLLE_EDIT = `${RouterLinkConstants.ROLLEN}/${PathConstants.DL_ROLLEN}/${PathConstants.ROLLE_EDIT}`;
    static readonly EIGENE_ROLLE_EDIT = `${RouterLinkConstants.ROLLEN}/${PathConstants.EIGENE_ROLLEN}/${PathConstants.ROLLE_EDIT}`;
    static readonly ROLLE_EDIT = `${RouterLinkConstants.ROLLEN}/${PathConstants.ROLLE_EDIT}`;

    static readonly STATUS = `/${PathConstants.STATUS}`;

    static readonly WAHLEN = `/${PathConstants.WAHLEN}`;
    static readonly ZENTRALE_WAHL = `/${PathConstants.ZENTRALE_WAHLEN}`;
}
