// @ts-strict-ignore
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'k5-edit-section',
    templateUrl: './edit-section.component.html',
    styleUrls: ['./edit-section.component.scss']
})
export class EditSectionComponent implements OnInit, OnDestroy {
    @Input()
    editSectionId: string = '';

    @Input()
    editSectionForm: FormGroup;

    @Input()
    heading: string = '';

    @Input()
    addActionHeading: string = '';

    @Input()
    customIcon: string;

    @Input()
    customIconColor: string;

    @Input()
    customIconTooltip: string;

    @Input()
    icon: string = '';

    @Input()
    isEditable: boolean = true;

    @Input()
    isAddable: boolean = false;

    @Output()
    editModeEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    addClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    customEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    isEditMode: boolean = false;
    subscription: Subscription = new Subscription();

    constructor(
        private globalEditService: GlobalEditService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.globalEditService.closeAllEditSections$.subscribe(() => {
                this.switchToReadMode();
            })
        );

        this.subscription.add(
            this.globalEditService.editSectionId$.subscribe((editSectionId: string) => {
                if (editSectionId === this.editSectionId && this.isEditMode === false) {
                    this.isEditMode = true;
                    this.editModeEnabled.emit(this.isEditMode);
                } else {
                    this.switchToReadMode();
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Gibt an, ob der Bearbeitungsbleistift sichtbar ist
     */
    get isEditPencilVisible(): boolean {
        return this.isEditable && !this.isEditMode && !this.globalEditService.isEditing;
    }

    /**
     * Gibt an, ob der Plus Button sichtbar ist
     */
    get isAddButtonVisible(): boolean {
        return this.isAddable && !this.isEditMode && !this.globalEditService.isEditing;
    }

    /**
     * Gibt an, ob der custom Button sichtbar ist
     */
    get isCustomButtonVisible(): boolean {
        return this.customIcon && !this.isEditMode && !this.globalEditService.isEditing;
    }

    /**
     * Gibt das Event an die Eltern Komponente weiter
     */
    triggerCustomEvent(): void {
        this.customEvent.emit(true);
    }

    /**
     * Wechselt in den Bearbeitungsmodus wenn der Bereich editiert werden darf
     */
    switchToEditMode(): void {
        if (this.globalEditService.isEditing) {
            this.alertService.error(
                'Es werden bereits Daten bearbeitet',
                'Bitte speichern Sie Ihre Änderungen bevor Sie fortfahren'
            );
            return;
        }

        if (this.isEditable) {
            this.globalEditService.setEditSectionForm(this.editSectionForm);
            this.globalEditService.switchToEditMode(this.editSectionId);
        }
    }

    /**
     * Benachrichtigt Eltern Komponente, dass ein neues Objekt hinzugefügt werden soll
     */
    switchToAddMode(): void {
        this.addClicked.emit(true);
    }

    /**
     * Wechselt in den Lesemodus
     */
    private switchToReadMode(): void {
        this.isEditMode = false;
    }
}
