<k5-alert
    [alertType]="problemAlert.alertType"
    [titleMessage]="problemAlert.titleMessage"
    [detailsAvailable]="detailsAvailable"
>
    <!-- Spacing zum Titel, da die unteren Komponenten nicht immer angezeigt werden müssen -->
    <div class="pt-2"></div>

    <!-- Case Id -->
    <div *ngIf="problemAlert?.problem?.caseId">Case Id: {{ problemAlert.problem.caseId }}</div>
    <!-- Detailnachricht -->
    <p *ngIf="problemAlert?.problem?.detail">
        {{ problemAlert.problem.detail }}
    </p>

    <!-- Weitere Informationen -->
    <section class="pt-2" *ngIf="detailValuesAvailable">
        <div>Weitere Informationen:</div>
        <p *ngFor="let message of problemAlert.problem.detailValues">
            {{ message }}
        </p>
    </section>
</k5-alert>
