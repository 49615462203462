<!-- container for fab -->
<div class="fab-container">
    <button
        mat-fab
        type="button"
        aria-label="Aktionen aufklappen"
        id="fab-toggler"
        color="primary"
        [matTooltip]="fabTogglerState === 'active' ? tooltipOpened : tooltipClosed"
        class="fab-menu-toggler"
        (click)="onToggleFab()"
        data-cy="aktionen-aufklappen-button"
    >
        <i
            *ngIf="fabTogglerState === 'inactive' && icon"
            class="fa-fw fa-solid text-2xl"
            [ngClass]="icon"
            aria-hidden="true"
        ></i>
        <i *ngIf="fabTogglerState === 'active' && icon" class="fa-fw fa-solid fa-xmark text-2xl" aria-hidden="true"></i>
        <i
            *ngIf="!icon"
            class="fa-fw fa-solid fa-plus text-2xl"
            [@fabToggler]="{ value: fabTogglerState }"
            aria-hidden="true"
        ></i>
    </button>
    <div [@fabMenuStagger]="{ value: fabTogglerState }">
        <div *ngFor="let button of buttons">
            <button mat-ripple type="button" class="fab-text" (click)="button.action()">
                {{ button.description }}
            </button>
            <button
                type="button"
                mat-mini-fab
                class="fab-button"
                [attr.aria-label]="button.description"
                (click)="button.action()"
                data-cy="option-button"
            >
                <i class="fg-gray fa-fw fa-duotone text-lg" [ngClass]="button.icon" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
