<div class="editable-list-item grid grid-cols-12 gap-x-4 gap-y-4" data-cy="editable-list-item">
    <div class="text-align-right fg-gray icon col-span-2">
        <div [ngClass]="isStandard ? 'fg-primary' : 'fg-gray'">
            <i [ngClass]="icon" class="fa-duotone" aria-hidden="true" [matTooltip]="iconTooltip"></i>
        </div>
    </div>
    <div class="content col-span-10">
        <div class="flex flex-col">
            <a class="font-bold" *ngIf="href" [href]="href" target="_blank" [matTooltip]="tooltip">{{ content }}</a>
            <span class="font-bold" *ngIf="!href" [matTooltip]="tooltip" data-cy="editable-list-item-content">{{
                content
            }}</span>
            <span *ngIf="addon" class="fg-gray text-xs">{{ addon }}</span>
        </div>

        <div class="pl-2">
            <i class="fa-duotone" [ngClass]="iconAfterContent.name" aria-hidden="true" *ngIf="iconAfterContent"></i>
        </div>

        <div class="pl-md flex" [ngClass]="actionButtonsVisible ? 'action-buttons' : 'hide-actions-buttons'">
            <div [matTooltip]="showAdditionalAction ? additionalActionTooltip : ''">
                <button
                    mat-icon-button
                    [disabled]="disableAdditionalAction"
                    [attr.aria-label]="additionalActionTooltip"
                    *ngIf="showAdditionalAction"
                    (click)="additionalAction()"
                    class="flex"
                    data-cy="additional-action-button"
                >
                    <mat-icon
                        fontSet="fa-duotone"
                        [fontIcon]="additionalActionIcon"
                        class="fg-primary text-base"
                    ></mat-icon>
                </button>
            </div>
            <div [matTooltip]="showEdit ? editTooltip : ''">
                <button
                    mat-icon-button
                    aria-label="Bearbeiten"
                    [matTooltip]="editTooltip"
                    [disabled]="disableEdit"
                    [ngClass]="showEdit ? '' : 'hide-actions-buttons'"
                    (click)="editItem()"
                    class="flex"
                    data-cy="edit-action-button"
                >
                    <mat-icon fontSet="fa-duotone" fontIcon="fa-pencil" class="fg-primary text-base"></mat-icon>
                </button>
            </div>
            <div [matTooltip]="showDelete ? deleteTooltip : ''">
                <button
                    aria-label="Löschen"
                    mat-icon-button
                    [disabled]="disableDelete"
                    [ngClass]="showDelete ? '' : 'hide-actions-buttons'"
                    (click)="deleteItem()"
                    class="flex"
                    data-cy="delete-action-button"
                >
                    <mat-icon fontSet="fa-duotone" fontIcon="fa-trash-alt" class="fg-danger text-base"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
